import { useCallback, useEffect, useState } from "react";
import {
  TitanCase,
  TitanCaseFile,
  TitanCaseFitting,
  TitanCaseStage,
} from "../../models";
import { useAppStoreHooks } from "../../toolkit";
import { textMessages } from "../../translations";
import {
  API_URLS,
  downloadFile,
  generateCaseReport,
  toothIdMap,
} from "../../lib";
import { useAppSelector } from "../../store";

const useCaseDetails = (caseId?: number) => {
  const [_case, setCase] = useState<TitanCase>();
  const company = useAppSelector((state) => state.cache.company.company);
  const features = useAppSelector((state) => state.appSettings.features);
  const { getJson, getRequest, postJson, loadingStart, loadingEnd, notify } =
    useAppStoreHooks();

  const loadCase = useCallback(
    async (caseId: number) => {
      loadingStart();
      try {
        const clientDetails = await getJson<TitanCase>(
          `${API_URLS.titan.cases.details}/${caseId}`
        );
        if (clientDetails) {
          setCase(clientDetails);
        } else {
          notify(textMessages.genericError, "error");
        }
        loadingEnd();
      } catch (e: any) {
        loadingEnd();
        notify(textMessages.genericError, "error", e.message);
      }
    },
    [getJson, loadingStart, loadingEnd, notify, setCase]
  );

  const onDownloadCaseReport = async () => {
    const primaryMap: string[] = [];
    _case!.caseElements?.forEach((ce) => {
      ce.teeth?.forEach((t) => {
        primaryMap.push(toothIdMap[t.toothId]);
      });
    });
    loadingStart();
    try {
      const response = await getRequest(API_URLS.titan.company.companyLogoLink);
      const logo = await response?.text();
      generateCaseReport(
        _case!,
        company!,
        logo || "",
        primaryMap,
        features.stages
      );
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, "error", e.message);
    }
  };

  const onDownloadCertificate = async () => {
    loadingStart();
    try {
      const response = await getRequest(
        `${API_URLS.titan.cases.downloadCertificateFile}/${caseId}`
      );
      const blob = await response?.blob();
      downloadFile(
        blob!,
        `certificat_confirmitate_caz_${caseId}_${_case!.clientCompanyName}/pdf`
      );
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, "error", e.message);
    }
  };

  const onToggleFinishCase = async (isFinished: boolean) => {
    loadingStart();
    try {
      await postJson(API_URLS.titan.cases.toggleIsFinished, {
        titanCaseId: _case!.titanCaseId,
        isFinished: isFinished,
      });
      setCase((prevState) => ({
        ...(prevState || ({} as TitanCase)),
        isFinished: isFinished,
      }));
      loadCase(caseId!);
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, "error", e.message);
    }
  };

  const onCasePaid = (amount?: number) => {
    setCase((prevState) => ({
      ...(prevState || ({} as TitanCase)),
      paid: amount,
    }));
  };

  const onFilesChanged = useCallback(
    (files: (File | TitanCaseFile)[]) => {
      setCase((prevState) => ({
        ...(prevState || ({} as TitanCase)),
        files,
      }));
    },
    [setCase]
  );

  const onFittingsChanged = (fittings: TitanCaseFitting[]) => {
    setCase((prevState) => ({
      ...(prevState || ({} as TitanCase)),
      caseFittings: fittings,
    }));
  };

  const onCaseStagesChanged = (caseStages: TitanCaseStage[]) => {
    setCase((prevState) => ({
      ...(prevState || ({} as TitanCase)),
      caseStages: caseStages,
    }));
  };

  const onNotesChanged = (notes: string, medicNotes: string) => {
    setCase((prevState) => ({
      ...(prevState || ({} as TitanCase)),
      notes,
      medicNotes,
    }));
  };

  useEffect(() => {
    if (caseId) {
      loadCase(caseId);
    }
  }, [loadCase, caseId]);

  return {
    _case,
    onDownloadCaseReport,
    onDownloadCertificate,
    onToggleFinishCase,
    onCasePaid,
    onFilesChanged,
    onFittingsChanged,
    onCaseStagesChanged,
    onNotesChanged,
  };
};

export default useCaseDetails;
