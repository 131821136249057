import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, PageLayout, Responsive } from "../../../layout";
import {
  ClinicDetailTables,
  ClinicReportTool,
  ClinicForm,
  useClinicDetails,
  ClinicDelete,
} from "../../../modules";
import { ClinicDetails } from "../../../components";
import { EditNoteIcon, Menu, MenuItem, Portal, TrashIcon } from "../../../toolkit";
import { useAppSelector } from "../../../store";

const ClinicDetailsPage: FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const params = useParams<{ clinicId: string; }>();
  const clinicId = params.clinicId ? Number(params.clinicId) : undefined;
  const clinic = useClinicDetails(clinicId);
  const clinicName = clinic?.clinicName || "Detalii clinica";
  return (
    <PageLayout
      title={clinicName}
      tabs={
        user?.isAdmin ? (
          <Menu text='Alte actiuni'>
            <MenuItem onClick={() => { setIsOpen(true); }}>
              <EditNoteIcon /> Modifica clinica
            </MenuItem>
            <MenuItem variant='danger' onClick={() => setIsDeleteOpen(true)}>
              <TrashIcon /> Sterge clinica
            </MenuItem>
          </Menu>
        ) : undefined
      }
    >
      {clinic && (
        <>
          <Container vertical gap={32}>
            <Responsive colsFrMd="1-2">
              <ClinicDetails clinic={clinic} />
              <ClinicReportTool
                clinicId={clinic.clinicId}
                clinicName={clinic.clinicName}
              />
            </Responsive>
            <ClinicDetailTables
              clinicId={clinic.clinicId}
              clinicName={clinic.clinicName}
            />
          </Container>
          <Portal
            isOpen={isOpen}
            onDismiss={() => {
              setIsOpen(false);
            }}
            title="Modifica Medic"
            overlay={true}
            width={560}
          >
            <ClinicForm
              clinic={clinic}
              onClose={() => {
                setIsOpen(false);
              }}
              onSaved={() => {
                setIsOpen(false);
              }}
            />
          </Portal>
          <Portal
            isOpen={isDeleteOpen}
            onDismiss={() => setIsDeleteOpen(false)}
            title='Sterge Clinica'
            overlay={true}
            width={560}
          >
            <ClinicDelete clinicId={clinic.clinicId!} onDismiss={() => setIsDeleteOpen(false)} />
          </Portal>
        </>
      )}
    </PageLayout>
  );
};

export default ClinicDetailsPage;
