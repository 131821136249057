import { FC, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { setCacheExpiry, useAppSelector } from "../store";
import AppLayout from "../layout/AppLayout/AppLayout";
import InitialLoadLayout from '../layout/InitialLoadLayout/InitialLoadLayout';
import { useCache, useSubscribedCache } from '../lib';
import { useAppStoreHooks } from '../toolkit';

const EnsureSubscribedCache: FC = () => {
  useSubscribedCache();

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

const AuthenticatedLayout: FC = () => {
  const subscription = useAppSelector(state => state.cache.subscription);
  const company = useAppSelector(state => state.cache.company);
  
  if (!subscription.loaded || !company.company) {
    return <InitialLoadLayout />;
  }
  return <EnsureSubscribedCache />;
};

const EnsureCache: FC = () => {
  const { dispatch } = useAppStoreHooks();
  useCache();

  useEffect(() => {
    dispatch(setCacheExpiry());
  }, [dispatch]);

  return (<AuthenticatedLayout />);
};


const PrivateRoute: FC = () => {
  const token = useAppSelector((state) => state.auth.token);

  if (!token?.value) {
    return <Navigate to="/login" />;
  }

  return <EnsureCache />;
};

export default PrivateRoute;
