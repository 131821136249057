import { useMemo, useState } from 'react';
import { TitanUser, TitanUserStage } from '../../models';
import { useAppStoreHooks } from '../../toolkit';
import { API_URLS, useEffectOnMount, validations } from '../../lib';
import { useAppSelector } from '../../store';
import { translateRole } from '../../resources';
import ApiError from '../../models/ApiError';
import { textMessages } from '../../translations';

const validateEdit = validations({
  firstName: [['required', 'Camp oligatoriu!']],
  lastName: [['required', 'Camp oligatoriu!']],
  email: [
    ['required', 'Camp oligatoriu!'],
    ['email', 'Adresa de email nu este valida!'],
  ],
  roleId: [['required', 'Camp oligatoriu!']],
});

const validateNew = validations({
  firstName: [['required', 'Camp oligatoriu!']],
  lastName: [['required', 'Camp oligatoriu!']],
  email: [
    ['required', 'Camp oligatoriu!'],
    ['email', 'Adresa de email nu este valida!'],
  ],
  roleId: [['required', 'Camp oligatoriu!']],
  password: [
    ['required', 'Camp oligatoriu!'],
    ['minLength', 'Lungimea minima este de 6 de caractere', '6'],
  ],
  confirmPassword: [
    ['required', 'Camp oligatoriu!'],
    ['minLength', 'Lungimea minima este de 6 de caractere', '6'],
  ],
});

const validate = (form: any, userIdentifier?: string) => {
  if (userIdentifier) {
    return validateEdit(form);
  }
  return validateNew(form);
};

type StageUserForm = TitanUserStage & {
  error?: string;
};

const useUser = (
  onSaved: () => void,
  user?: TitanUser,
  roleName?: string,
  isMyUserForm?: boolean
) => {
  const [userForm, setUserForm] = useState<{
    form: TitanUser & {
      roleName?: string;
      roleId?: string;
      password?: string;
      confirmPassword?: string;
    };
    errors: {
      firstName?: string;
      lastName?: string;
      email?: string;
      roleId?: string;
      roleName?: string;
      password?: string;
      confirmPassword?: string;
    };
    userStages: StageUserForm[];
    userStagesToRemove: number[];
  }>({
    form: {
      ...(user || ({} as TitanUser)),
      roleId: user?.roles?.[0]?.roleId,
      roleName,
    },
    errors: {},
    userStages: [],
    userStagesToRemove: [],
  });
  const { roles, stages } = useAppSelector((state) => state.cache);
  const {
    getJson,
    postJson,
    putJson,
    deleteRequest,
    notify,
    loadingStart,
    loadingEnd,
  } = useAppStoreHooks();
  const stagesOptions = useMemo(
    () => stages.data?.map((e) => ({ key: e.stageId!, name: e.name })),
    [stages]
  );
  const rolesOptions = useMemo(
    () =>
      roles.data
        ?.filter((r) => r.name.includes('Dentops'))
        .map((e) => ({ key: e.roleId!, name: translateRole(e.name) })),
    [roles]
  );
  const technicianRoleId = useMemo(
    () => roles.data?.find((r) => r.name === 'DentopsTechnician')?.roleId,
    [roles]
  );

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setUserForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [target.name]: target.value,
      },
    }));
  };

  const onDropdownChange = (roleId: string) => {
    setUserForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        roleId,
      },
    }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let form = { ...userForm.form };
    const userStageError =
      (userForm.userStages || [])
        ?.map((us) => us.error)
        .flat()
        .filter((u) => u)?.length > 0;
    if (userStageError) {
      return;
    }
    const errors = validate(form, user?.userIdentifier);
    const hasErrors = Object.keys(errors).length > 0;
    setUserForm((prevState) => ({
      ...prevState,
      errors,
    }));

    if (hasErrors) {
      return;
    }
    loadingStart();
    try {
      if (user?.userIdentifier) {
        // API_URLS.titan.user.update
        await postJson<{ model: TitanUser }>(API_URLS.identity.user.update, {
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          titanUserId: form.userIdentifier,
          roles: [form.roleId],
        });
      } else {
        const response = await postJson<{ model: TitanUser }>(
          API_URLS.identity.user.user,
          {
            firstName: form.firstName,
            lastName: form.lastName,
            email: form.email,
            password: form.password,
            confirmPassword: form.confirmPassword,
            roles: [form.roleId],
          }
        );
        user = response.model;
      }

      userForm.userStages.forEach(async (us) => {
        if (us.userStageId > 0) {
          await putJson<TitanUser>(API_URLS.titan.stage.userStage, {
            userStageId: us.userStageId,
            stageId: us.stageId,
            titanUserId: user!.userIdentifier,
            value: Number(us.value),
            isPercent: us.isPercent,
          });
        } else {
          await postJson<TitanUser>(API_URLS.titan.stage.userStage, {
            stageId: us.stageId,
            titanUserId: user!.userIdentifier,
            value: Number(us.value),
            isPercent: us.isPercent,
          });
        }
      });

      userForm.userStagesToRemove.forEach(async (userStageId) => {
        await deleteRequest(`${API_URLS.titan.stage.userStage}/${userStageId}`);
      });
      loadingEnd();
      notify('Utilizatorul a fost salvat cu succes', 'success');
      onSaved();
    } catch (e: any) {
      loadingEnd();
      const error = e as ApiError;
      if (error.status === 409) {
        notify(textMessages.emailAlreadyExists, 'error');
      } else {
        notify(e.message, 'error');
      }
    }
  };

  const onStageChanged = (stageId: number) => {
    const stage = stages.data?.find((s) => s.stageId === stageId);
    if (stage) {
      setUserForm((prevState) => ({
        ...prevState,
        userStages: [
          ...prevState.userStages,
          { stageId: stage.stageId, stageName: stage.name } as TitanUserStage,
        ],
      }));
    }
  };

  const onStageValueChanged = (stageId: number, value: string) => {
    const v = Number(value.replace('.', ''));
    if (isNaN(Number(v))) {
      return;
    }
    const index = userForm.userStages.findIndex((us) => us.stageId === stageId);
    if (index > -1) {
      const userStagesCopy = [...userForm.userStages];
      userStagesCopy[index] = {
        ...userStagesCopy[index],
        value: value,
        error:
          userStagesCopy[index]?.isPercent && Number(value) > 100
            ? 'Procentul nu poate depasi 100'
            : undefined,
      };
      setUserForm((prevState) => ({
        ...prevState,
        userStages: userStagesCopy,
      }));
    }
  };

  const onStageIsPercentChanged = (stageId: number, value: boolean) => {
    const index = userForm.userStages.findIndex((us) => us.stageId === stageId);
    if (index > -1) {
      const userStagesCopy = [...userForm.userStages];
      userStagesCopy[index] = {
        ...userStagesCopy[index],
        isPercent: value,
        error:
          value && Number(userStagesCopy[index].value) > 100
            ? 'Procentul nu poate depasi 100'
            : undefined,
      };
      setUserForm((prevState) => ({
        ...prevState,
        userStages: userStagesCopy,
      }));
    }
  };

  const onRemoveUserStage = (index: number) => {
    const userStage = userForm.userStages.find((_, i) => i === index);
    setUserForm((prevState) => ({
      ...prevState,
      userStages: prevState.userStages.filter((_, i) => i !== index),
      userStagesToRemove: userStage?.userStageId
        ? [...prevState.userStagesToRemove, userStage?.userStageId]
        : prevState.userStagesToRemove,
    }));
  };

  const titanUserId = user?.userIdentifier;
  useEffectOnMount(() => {
    if (titanUserId && !isMyUserForm) {
      (async () => {
        loadingStart();
        try {
          const userStages = await getJson<TitanUserStage[]>(
            `${API_URLS.titan.stage.userStage}/${titanUserId}`
          );
          setUserForm((prevState) => ({
            ...prevState,
            userStages,
          }));
          loadingEnd();
        } catch (e: any) {
          loadingEnd();
          notify(e.message, 'error');
        }
      })();
    }
  }, [titanUserId, getJson, loadingStart, loadingEnd, notify, isMyUserForm]);

  return {
    userForm,
    stagesOptions: stagesOptions?.filter(
      (so) => userForm.userStages?.some((es) => es.stageId === so.key) === false
    ),
    rolesOptions,
    showStages: technicianRoleId === userForm.form.roleId,
    onDropdownChange,
    onChange,
    onSubmit,
    onStageChanged,
    onStageValueChanged,
    onStageIsPercentChanged,
    onRemoveUserStage,
  };
};

export default useUser;
