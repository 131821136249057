import { useNavigate } from 'react-router-dom';
import { useAppStoreHooks } from '../../toolkit';
import { setRefreshClients } from '../../store';
import { API_URLS } from '../../lib';

const useClientDelete = (titanClientId: number) => {
  const navigate = useNavigate();
  const { deleteRequest, loadingStart, loadingEnd, notify, dispatch } = useAppStoreHooks();


  const onDelete = async () => {
    loadingStart();
    try {
      await deleteRequest(`${API_URLS.titan.clients.delete}/${titanClientId}`);
      notify('Medicul a fost sters cu succes.', 'success');
      loadingEnd();
      dispatch(setRefreshClients());
      navigate('/clients');
    } catch (e: any) {
      loadingEnd();
      notify(e.message, 'error');
    }
  };

  return { onDelete };
};

export default useClientDelete;