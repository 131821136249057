import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppStoreHooks } from '../toolkit';
import { API_URLS } from '../lib';
import { onTokenRefreshError, onTokenRefreshSuccess, setCacheExpiry, useAppSelector } from '../store';
import { TitanToken, TitanUser } from '../models';

type RefreshTokenResponse = {
  isSuccessful: boolean;
  model: { token: TitanToken; user: TitanUser; };
};


const RefreshTokenAndRedirect: FC<{ redirectTo: string; }> = ({ redirectTo }) => {
  const user = useAppSelector((state) => state.auth.user);

  const { postJson, dispatch } = useAppStoreHooks();

  (async () => {
    try {
      const response = await postJson<RefreshTokenResponse>(API_URLS.identity.refresh, { email: user?.email, refreshToken: user?.refreshToken });
      dispatch(onTokenRefreshSuccess({ token: response.model.token, refreshToken: response.model.user.refreshToken }));
    } catch (e: any) {
      dispatch(onTokenRefreshError());
      dispatch(setCacheExpiry({ expireAfterMinutes: -1 }));
    }
  })();

  return <Navigate to={redirectTo} />;
};

export default RefreshTokenAndRedirect;
