import { FC } from "react";
import {
  Button,
  Card,
  CardIcon,
  ContainerFlex,
  MasterCardIcon,
  Typography,
  VisaIcon,
} from "../../toolkit";
import styles from "./styles.module.css";
import { SUBSCRIPTIONS } from "../SubscriptionPlan/SubscriptionPlan";
import { Responsive } from "../layout";
import { prettyDate } from "../../lib";
import { TitanPaymentMethod, TitanSubscriptionDetails } from "../../models";

// type SubscriptionConfig = {
//   icon: ReactNode;
//   title: string;
//   details: string;
//   price: string;
//   discount?: string;
// };

type Props = {
  type: keyof typeof SUBSCRIPTIONS;
  currentSubscription: TitanSubscriptionDetails;
  paymentMethod?: TitanPaymentMethod;
  onCancel: () => void;
  onRestore: () => void;
  onChangeCard: () => void;
};

const CurrentSubscriptionPlan: FC<Props> = ({
  type,
  currentSubscription,
  paymentMethod,
  onCancel,
  onRestore,
  onChangeCard,
}) => {
  const otherPlans = Object.keys(SUBSCRIPTIONS).filter((key) => key !== type);

  return (
    <Card className={styles.planCard}>
      <Responsive colsLg={2}>
        <ContainerFlex type="column" spacing={2} justify="space-between">
          <ContainerFlex type="row" spacing={2}>
            {SUBSCRIPTIONS[type].icon}
            <ContainerFlex grow={true} className={styles.plan}>
              <Typography type="h4">{SUBSCRIPTIONS[type].title}</Typography>
              <Typography type="span" variation="secondary">
                Planul activ este {SUBSCRIPTIONS[type].details}
              </Typography>
              <br />
              {!currentSubscription.cancelAtPeriodEnd && currentSubscription.status !== 'past_due' && (
                <Typography type="span" variation="secondary">
                  Uramtoare plata se va face in{" "}
                  <strong>
                    {prettyDate(currentSubscription?.currentPeriodEnd!)}
                  </strong>
                </Typography>
              )}
              {currentSubscription.status === 'past_due' && (
                <Typography type="span" variation="secondary" variation1='danger'>
                  Ultima plata nu a fost cu success!
                </Typography>
              )}
              {currentSubscription.cancelAtPeriodEnd && (
                <>
                  <Typography type="span" variation="secondary">
                    Subscriptia este valabila pana in{" "}
                    <strong>
                      {prettyDate(currentSubscription?.currentPeriodEnd)}
                    </strong>.
                  </Typography>
                  <br />
                  <Typography type="span" variation="bold">
                    Aplicatia nu va mai fi accesibila dupa aceasta data.
                  </Typography>
                </>
              )}
            </ContainerFlex>
            <div>
              {currentSubscription.cancelAtPeriodEnd && (
                <Button
                  type="button"
                  size="small"
                  variant="text"
                  onClick={onRestore}
                >
                  Restaureaza
                </Button>
              )}
              {!currentSubscription.cancelAtPeriodEnd && (
                <Button
                  type="button"
                  size="small"
                  variant="text"
                  onClick={onCancel}
                >
                  Anuleaza
                </Button>
              )}
            </div>
          </ContainerFlex>
          {paymentMethod && (
            <Card type="row" justify="space-between" spacing={2}>
              <ContainerFlex grow={true}>
                <ContainerFlex type="row" spacing={2}>
                  <ContainerFlex>
                    {paymentMethod.brand === "visa" && <VisaIcon />}
                    {paymentMethod.brand === "mastercard" && <MasterCardIcon />}
                    {paymentMethod.brand !== "visa" &&
                      paymentMethod.brand !== "mastercard" && <CardIcon />}
                  </ContainerFlex>
                  <Typography type="span" variation="bold">
                    **** **** **** {paymentMethod.last4}
                  </Typography>
                </ContainerFlex>
                <Typography type="span" variation="secondary">
                  Expira la {paymentMethod.expMonth}/{paymentMethod.expYear}
                </Typography>
              </ContainerFlex>
              <div>
                <Button
                  type="button"
                  size="small"
                  variant="text"
                  onClick={onChangeCard}
                >
                  Schimba
                </Button>
              </div>
            </Card>
          )}
        </ContainerFlex>
        <ContainerFlex type="column" spacing={2}>
          {otherPlans.map((plan) => (
            <Card key={plan} type="row" justify="space-between">
              <div>
                <Typography type="h4">{SUBSCRIPTIONS[plan].title}</Typography>
                <Typography type="span" variation="secondary">
                  Schimba cu {SUBSCRIPTIONS[plan].details}
                </Typography>
              </div>
              <div>
                <Button
                  type="button"
                  size="small"
                  variant="text"
                  disabled={true}
                >
                  Alege
                </Button>
              </div>
            </Card>
          ))}
        </ContainerFlex>
      </Responsive>
    </Card>
  );
};

export default CurrentSubscriptionPlan;
