import { FC } from 'react';
import { AnimatedAppIcon, Card, ContainerFlex, Delimiter, IconButton, Input, StagesIcon, Toggle, TrashIcon, Typeahead, TypeaheadOption, Typography } from '../../../toolkit';
import { TitanUserStage } from '../../../models';

type Props = {
  userStages: (TitanUserStage & { error?: string; })[];
  stagesOptions: TypeaheadOption[];
  onStageChanged: (stageId: number) => void;
  onStageValueChanged: (stageId: number, value: string) => void;
  onStageIsPercentChanged: (stageId: number, value: boolean) => void;
  onRemoveUserStage: (index: number) => void;
};

const UserFormConfigureStages: FC<Props> = ({
  userStages,
  stagesOptions,
  onStageChanged,
  onStageValueChanged,
  onStageIsPercentChanged,
  onRemoveUserStage,
}) => {
  return (
    <ContainerFlex type="column" spacing={1}>
      <Typeahead
        label="Alege etapa"
        options={stagesOptions || []}
        onChange={selectedKey => onStageChanged(selectedKey as number)}
      />
      <ContainerFlex type="column" spacing={1}>
        {userStages.map((stage, index) => (
          <Card key={stage.stageId} variant='secondary' type="column" spacing={2}>
            <ContainerFlex type="row" justify="space-between">
              <Typography type="p">{stage.stageName}</Typography>
              <IconButton variant='outlined' color='danger' onClick={() => onRemoveUserStage(index)}>
                <TrashIcon />
              </IconButton>
            </ContainerFlex>
            <Delimiter />
            <ContainerFlex type="row" spacing={2}>
              <Input
                placeholder="Adauga castigul tehnicianului"
                type="text"
                onChange={e => onStageValueChanged(stage.stageId, e.target.value)}
                value={stage.value !== undefined ? String(stage.value) : ""}
                errorMessage={stage.error}
              />
              <div style={{ paddingTop: 10 }}>
                <Toggle
                  value={stage.isPercent}
                  onChange={(_, checked) => onStageIsPercentChanged(stage.stageId, checked!)}
                  label="Procent" />
              </div>
            </ContainerFlex>
          </Card>
        ))}
        {userStages.length === 0 && (
          <ContainerFlex type='column' grow align='center' justify='center' spacing={3}>
            <AnimatedAppIcon>
              <StagesIcon size={40} />
            </AnimatedAppIcon>
            <Typography type='p' variation="center">
              Nu exista etape asociate.<br />
              <Typography type='span' variation='secondary'>
                Alege o etapa pentru a incepe.
              </Typography>
            </Typography>

          </ContainerFlex>
        )}
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default UserFormConfigureStages;