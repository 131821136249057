import { FC, useRef, useState } from 'react';
import MonthCalendarGrid from '../MonthCalendarGrid/MonthCalendarGrid';
import Portal from '../../Portal/Portal';
import { getDaysInMonth, getDefaultSingleState } from '../utils';
import { prettyDate } from '../../../lib';
import styles from './styles.module.css';
import Typography from '../../Typography/Typography';
import { CalendarIcon } from '../../assets';

type Props = {
  label?: string;
  date: Date;
  onDateChanged: (date: Date) => void;
  isDisabled?: boolean;
};

const DateInput: FC<Props> = ({ label, date, onDateChanged, isDisabled }) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dates, setDates] = useState<{
    startDate?: Date;
    endDate?: Date;
    refStartDate: Date;
    refEndDate: Date;
  }>(getDefaultSingleState(date));


  const onSelectDate = (date: Date) => {
    setDates(prevState => ({
      ...prevState,
      startDate: date,
      endDate: date
    }));
    onDateChanged(date);
  };

  const onMonthChanged = (month: number) => {
    setDates(prevState => ({
      ...prevState,
      refStartDate: new Date(prevState.refStartDate.getFullYear(), prevState.refStartDate.getMonth() + month, 1),
      refEndDate: new Date(prevState.refEndDate.getFullYear(), prevState.refEndDate.getMonth() + month, 1)
    }));
  };

  const days = getDaysInMonth(dates.refStartDate.getFullYear(), dates.refStartDate.getMonth(), dates.startDate, dates.endDate);

  return (
    <div className={styles.dateInput}>
      {label && (
        <Typography type='label' variation='bold'>
          {label}<span className={styles.required}>{' '}*</span>
        </Typography>
      )}
      
      <button ref={ref} onClick={() => { setIsOpen(true); }} disabled={isDisabled}>
        <span>
          {prettyDate(date)}
        </span>
        <CalendarIcon />
      </button>
      <Portal
        isOpen={isOpen}
        anchorEl={ref.current}
        align='left'
        onDismiss={() => {
          setIsOpen(false);
        }}
        width={273}
        overlay={false}
      >
        <MonthCalendarGrid refDate={dates.refStartDate} days={days} onDateChange={onSelectDate} onMonthChanged={onMonthChanged} />
      </Portal>
    </div>
  );
};

export default DateInput;