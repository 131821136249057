import { useMemo, useState } from 'react';
import { TitanElementStage, TitanStage, TitanUserStage } from '../../models';
import { API_URLS, validations } from '../../lib';
import { useAppStoreHooks } from '../../toolkit';
import { textMessages } from '../../translations';
import { useAppSelector } from '../../store';

type StageUserForm = TitanUserStage & {
  error?: string;
};

type TitanStageForm = Omit<TitanStage, 'stageUsers'> & {
  stageUsers?: StageUserForm[];
};

const validate = validations({
  name: [['required', 'Camp oligatoriu!']],
});

const useStage = (onSaved: () => void, stage?: TitanStage) => {
  const [stageForm, setStageForm] = useState<{
    form: TitanStageForm;
    errors: {
      name?: string;
      description?: string;
    };
  }>({
    form: stage || ({} as TitanStageForm),
    errors: {},
  });
  const { elements, technicians } = useAppSelector((state) => state.cache);
  const { putJson, postJson, notify, loadingStart, loadingEnd } =
    useAppStoreHooks();
  const elementOptions = useMemo(
    () => elements.data?.map((e) => ({ key: e.elementId!, name: e.name })),
    [elements]
  );
  const userOptions = useMemo(
    () =>
      technicians.data?.map((e) => ({
        key: e.userIdentifier!,
        name: `${e.lastName} ${e.firstName}`,
      })),
    [technicians]
  );

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setStageForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [target.name]: target.value,
      },
    }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let stage = { ...stageForm.form };
    const errors = validate(stage);
    const hasError = Object.keys(errors).length;
    setStageForm((prevState) => ({
      ...prevState,
      errors,
    }));
    const userStageError =
      (stage.stageUsers || [])
        ?.map((us) => us.error)
        .flat()
        .filter((u) => u)?.length > 0;
    if (hasError && userStageError) {
      return;
    }
    loadingStart();
    try {
      const stageElements = [...(stage.stageElements || [])];
      const elementIds = stageElements?.map((e) => e.elementId);
      const stageUsers = stage.stageUsers?.map((u) => ({
        titanUserId: u.userIdentifier,
        value: Number(u.value),
        isPercent: u.isPercent,
      }));
      delete stage.stageElements;
      delete stage.stageUsers;
      if (stage.stageId) {
        await putJson<{}>(API_URLS.titan.stage.stage, {
          ...stage,
          elementIds,
          stageUsers,
        });
        notify('Etapa a fost modificat.', 'success');
      } else {
        stage = await postJson<TitanStage>(API_URLS.titan.stage.stage, {
          ...stage,
          elementIds,
          stageUsers,
        });
        notify('Etapa a fost adaugat.', 'success');
      }
      loadingEnd();
      onSaved();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error', e.message);
    }
  };

  const onUserStagesChanged = (userStages: TitanUserStage[]) => {
    setStageForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        stageUsers: userStages,
      },
    }));
  };

  const onElementChanged = (elementId: number) => {
    const element = elements.data?.find((s) => s.elementId === elementId);
    if (element) {
      setStageForm((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          stageElements: [
            ...(prevState.form.stageElements || []),
            {
              elementId: element.elementId,
              elementName: element.name,
            } as TitanElementStage,
          ],
        },
      }));
    }
  };

  const onRemoveElementStage = (index?: number) => {
    setStageForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        stageElements: (prevState.form.stageElements || []).filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  const onUserChanged = (userIdentifier: string) => {
    const user = technicians.data?.find(
      (s) => s.userIdentifier === userIdentifier
    );
    if (user) {
      setStageForm((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          stageUsers: [
            ...(prevState.form.stageUsers || []),
            {
              userIdentifier: user.userIdentifier,
              firstName: user.firstName,
              lastName: user.lastName,
              value: 0,
            } as TitanUserStage,
          ],
        },
      }));
    }
  };

  const onStageValueChanged = (userIdentifier: string, value: string) => {
    const v = Number(value.replace('.', ''));
    if (isNaN(Number(v))) {
      return;
    }
    const userStagesCopy = [...(stageForm.form.stageUsers || [])];
    const index = userStagesCopy.findIndex(
      (us) => us.userIdentifier === userIdentifier
    );
    if (index > -1) {
      userStagesCopy[index] = {
        ...userStagesCopy[index],
        value: value,
        error:
          userStagesCopy[index]?.isPercent && Number(value) > 100
            ? 'Procentul nu poate depasi 100'
            : undefined,
      };
      setStageForm((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          stageUsers: userStagesCopy,
        },
      }));
    }
  };

  const onStageIsPercentChanged = (userIdentifier: string, value: boolean) => {
    const userStagesCopy = [...(stageForm.form.stageUsers || [])];
    const index = userStagesCopy.findIndex(
      (us) => us.userIdentifier === userIdentifier
    );
    if (index > -1) {
      userStagesCopy[index] = {
        ...userStagesCopy[index],
        isPercent: value,
        error:
          value && Number(userStagesCopy[index].value) > 100
            ? 'Procentul nu poate depasi 100'
            : undefined,
      };
      setStageForm((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          stageUsers: userStagesCopy,
        },
      }));
    }
  };

  const onRemoveStageUser = (index?: number) => {
    setStageForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        stageUsers: (prevState.form.stageUsers || []).filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  return {
    stageForm,
    elementOptions: stageForm.form.stageElements
      ? elementOptions?.filter(
          (eo) =>
            stageForm.form.stageElements?.some(
              (se) => se.elementId === eo.key
            ) === false
        )
      : elementOptions,
    userOptions: stageForm.form.stageUsers
      ? userOptions?.filter(
          (uo) =>
            stageForm.form.stageUsers?.some(
              (su) => su.userIdentifier === uo.key
            ) === false
        )
      : userOptions,
    onChange,
    onSubmit,
    onElementChanged,
    onRemoveElementStage,

    onUserChanged,
    onStageValueChanged,
    onStageIsPercentChanged,
    onRemoveStageUser,
    onUserStagesChanged,
  };
};

export default useStage;
