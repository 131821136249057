import { useCallback, useEffect } from 'react';
import { TableProps } from '../types';
import { useAppStoreHooks } from '../../toolkit';
import { useAppSelector, setInternalPage, setPaginatedInternalList } from '../../store';

type Params = Record<string, string | number | boolean>;

const defaultTableState = { results: [], page: 0 };

const useInternalPaginatedListV1 = (
  url: string,
  filterFunc?: (searchTerm: string) => (row: any) => boolean,
  params?: { sortingParams?: Params; queryParams: Params }
): TableProps => {
  const tableState =
    useAppSelector((state) => state.internalPaginatedList.value[url]) ||
    defaultTableState;

  // const [tableState, setTableState] = useState<{
  //   page: number;
  //   total?: number;
  //   searchTerm?: string;
  //   filtered?: any[];
  //   results: any[];
  //   queryParams?: Params;
  //   sortingParams?: Params;
  // }>({
  //   page: 0,
  //   results: [],
  //   sortingParams: params?.sortingParams,
  //   queryParams: params?.queryParams,
  // });

  const { getJson, dispatch, notify, loadingStart, loadingEnd } =
    useAppStoreHooks();
  const loadList = useCallback(
    async (queryParams?: Params, sortingParams?: Params) => {
      loadingStart();
      try {
        const results = await getJson<any[]>(url);
        dispatch(
          setPaginatedInternalList({
            pageKey: url,
            results: [...(results || [])],
            count: results.length,
            loaded: true,
          })
        );
        loadingEnd();
      } catch (e: any) {
        notify(e.message || e, 'error');
        loadingEnd();
      }
    },
    [url, dispatch, getJson, notify, loadingStart, loadingEnd]
  );

  const changePage = useCallback(
    (page: number) => {
      dispatch(setInternalPage({ pageKey: url, page }));
    },
    [dispatch, url]
  );

  // const setSortingParam = useCallback((sortingParams?: Params) => {
  //   setTableState(prevState => ({
  //     ...prevState,
  //     sortingParams
  //   }));
  // }, [setTableState]);

  // const setSearchTerm = useCallback((searchTerm?: string) => {
  //   const filtered = !searchTerm || !filterFunc
  //     ? undefined
  //     : tableState.results.filter(filterFunc(searchTerm));
  //   setTableState((prevState) => ({
  //     ...prevState,
  //     filtered,
  //     searchTerm,
  //   }));
  // }, [filterFunc]);

  const reload = useCallback(
    (reset?: boolean) => {
      if (reset) {
        changePage(0);
      }
      loadList(tableState.queryParams, tableState.sortingParams);
    },
    [changePage, tableState.queryParams, tableState.sortingParams, loadList]
  );

  // const setQueryParam = useCallback(
  //   (queryParams?: Params) => {
  //     setTableState((prevState) => ({
  //       ...prevState,
  //       queryParams,
  //     }));
  //   },
  //   [setTableState]
  // );

  useEffect(() => {
    loadList(tableState.queryParams, tableState.sortingParams);
  }, [loadList, tableState.queryParams, tableState.sortingParams]);

  return {
    loading: tableState.loading,
    isFiltered: Boolean(tableState.queryParams?.searchTerm),
    page: tableState.page,
    results: tableState.results,
    total: tableState.count || 0,
    sortingParams: tableState.sortingParams,
    setSearchTerm: () => {},
    setQueryParam: () => {},
    setSortingParam: () => {},
    reload,
    changePage,
  };
};

export default useInternalPaginatedListV1;
