import { useNavigate } from 'react-router-dom';
import { useAppStoreHooks } from '../../toolkit';
import { setRefreshClinics } from '../../store';
import { API_URLS } from '../../lib';

const useClinicDelete = (clinicId: number) => {
  const navigate = useNavigate();
  const { deleteRequest, loadingStart, loadingEnd, notify, dispatch } = useAppStoreHooks();


  const onDelete = async () => {
    loadingStart();
    try {
      await deleteRequest(`${API_URLS.titan.clinic.clinic}/${clinicId}`);
      notify('Clinica a fost stersa cu succes.', 'success');
      loadingEnd();
      dispatch(setRefreshClinics());
      navigate('/clients');
    } catch (e: any) {
      loadingEnd();
      notify(e.message, 'error');
    }
  };

  return { onDelete };
};

export default useClinicDelete;