import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TitanUnitMeasure } from '../models';

export interface UnitMeasureState {
  value: TitanUnitMeasure[];
}

const initialState: UnitMeasureState = {
  value: [],
};

export const unitMeasuresSlice = createSlice({
  name: 'unitMeasures',
  initialState,
  reducers: {
    setUnitMesures: (state, action: PayloadAction<TitanUnitMeasure[]>) => {
      state.value = [...action.payload];
    },
  },
});

export const { setUnitMesures } = unitMeasuresSlice.actions;

export default unitMeasuresSlice.reducer;
