const messages = {
  validEmailError: 'Adresa de email nu este valida!',
  requiredError: 'Camp oligatoriu!',
  emailOrPasswordIcorrectError: 'Email sau parola incorecta!',
  accountCreatedSuccess: 'Contul a fost creat!',
  genericError: 'A aparut o eroare!',
  emailSentSuccess: 'Emailul a fost trimis!',
  passwordResetSuccess: 'Parola a fost resetata',
  passwordResetError: 'Resetarea parolei nu a avut success!',
  passwordNotmatch: 'Combinatia nu e corecta!',
  accountVerificationFailed: 'Contul nu a fost verificat.',
  verifyEmail: 'Te rog verifica adresa de email.',
  changesHaveBeenSaved: 'Modificarile au fost salvate',
  emailAlreadyExists: 'Adresa de email este deja folosita!'
};

export default messages;