import { FC, useState } from 'react';
import { TitanCase } from '../../../models';
import { ContainerFlex, Typography, Card, Delimiter, Menu, EditNoteIcon, MenuItem, TrashIcon, EditPencilIcon, Portal, useAppStoreHooks } from '../../../toolkit';
import { HumanArcade } from '../../../components';
import { toothIdMap } from '../../../lib';
import { Responsive } from '../../../layout';
import CaseEditNotes from './CaseEditNotes';
import CaseDelete from './CaseDelete';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../store';

type Props = {
  _case: TitanCase;
  onNotesChanged: (notes: string, medicNotes: string) => void;
};

const CaseDetails: FC<Props> = ({ _case, onNotesChanged }) => {
  const user = useAppSelector(state => state.auth.user);
  const navigate = useNavigate();
  const [isEditNotesOpen, setEditNotesOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const { notify } = useAppStoreHooks();
  const primaryMap: string[] = [];
  _case!.caseElements?.forEach(ce => {
    ce.teeth?.forEach(t => {
      primaryMap.push(toothIdMap[t.toothId]);
    });
  });

  return (
    <>
      <Card type='column' spacing={3}>
        <ContainerFlex type='row' justify='space-between'>
          <div>
            <Typography type='h5'>Informatii Lucrare</Typography>
            <Typography type='span' variation="secondary">Ref. interna {_case.internalReference}</Typography>
          </div>
          {user?.isManagerOrAbove && (
            <Menu text='Alte actiuni'>
              <MenuItem onClick={() => _case.paid ? notify('Cazul nu poate fi editat daca are plati atasate', 'error') : navigate(`/cases/${_case.titanCaseId}/edit`)}>
                <EditNoteIcon /> Modifica caz
              </MenuItem>
              <MenuItem onClick={() => setEditNotesOpen(true)}>
                <EditPencilIcon /> Adauga notite
              </MenuItem>
              <MenuItem variant='danger' onClick={() => setIsDeleteOpen(true)}>
                <TrashIcon /> Sterge caz
              </MenuItem>
            </Menu>
          )}
        </ContainerFlex>
        <Delimiter />
        <ContainerFlex type='row' spacing={3}>
          <ContainerFlex grow>
            <div>
              <Typography type='label' variation='secondary'>Culoare</Typography>
              <Typography type='p' variation='bold'>{_case.color}&nbsp;</Typography>
            </div>
            <div>
              <Typography type='label' variation='secondary'>Sumar Elemente</Typography>
              <ContainerFlex type='column' spacing={2}>
                {_case.caseElements.map(ce => (
                  <ContainerFlex key={ce.caseElementId} type='column' spacing={1}>
                    <Typography type='p' variation='bold'>{ce.count}x {ce.name}</Typography>
                    {/* <div>Add the teeth here</div> */}
                  </ContainerFlex>
                ))}
              </ContainerFlex>
            </div>

          </ContainerFlex>
          <HumanArcade primaryMap={primaryMap} readonly={true} />
        </ContainerFlex>
        <Delimiter />
        <Responsive colsSm={2}>
          <div>
            <Typography type='label' variation='secondary'>Notite medic</Typography>
            <Typography type='p' variation='bold'>{_case.medicNotes}&nbsp;</Typography>
          </div>
          <div>
            <Typography type='label' variation='secondary'>Notite Tehnician</Typography>
            <Typography type='p' variation='bold'>{_case.notes}&nbsp;</Typography>
          </div>
        </Responsive>
      </Card>
      <Portal
        isOpen={isEditNotesOpen}
        onDismiss={() => setEditNotesOpen(false)}
        title='Adauga notite'
        overlay={true}
        width={560}
      >
        <CaseEditNotes _case={_case} onClose={() => setEditNotesOpen(false)} onNotesChanged={onNotesChanged} />
      </Portal>
      <Portal
        isOpen={isDeleteOpen}
        onDismiss={() => setIsDeleteOpen(false)}
        title='Sterge caz'
        overlay={true}
        width={560}
      >
        <CaseDelete caseId={_case.titanCaseId!} onDismiss={() => setIsDeleteOpen(false)} />
      </Portal>
    </>
  );
};

export default CaseDetails;