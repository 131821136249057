import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PaginatedListState } from '../lib';

type BasePayload = {
  pageKey: string;
};

type UrlQueryParamsPayload = {
  queryParams?: { [key: string]: any };
  sortingParams?: { [key: string]: any };
} & BasePayload;

type PagePayload = {
  page: number;
} & BasePayload;

type LoadingPayload = {
  loading: boolean;
} & BasePayload;

type SetPaginatedListPayload = {
  count: number;
  results: Array<any>;
  loaded: boolean;
} & UrlQueryParamsPayload;

type InternalPaginatedListState<T> = PaginatedListState<T> & {
  internalResults: any[];
};

export const initialInternalListState: InternalPaginatedListState<any> = {
  loading: false,
  loaded: false,
  page: 0,
  internalResults: [],
  results: [],
};

const initialState: {
  value: { [key: string]: InternalPaginatedListState<any> };
} = {
  value: {},
};

const getCurrentPageResults = (results: any[], page: number) => {
  const final = results || [];
  const start = (page - 1) * 10;
  const end = final.length - 1 - start < 0 ? final.length - 1 : start + 10;

  return final.slice(start, end);
};

export const internalPaginatedListSlice = createSlice({
  name: 'paginatedList',
  initialState,
  reducers: {
    reloadInternalList: (
      state,
      action: PayloadAction<UrlQueryParamsPayload>
    ) => {
      const { pageKey = '', ...rest } = action.payload || {};
      let newList: InternalPaginatedListState<any> = initialInternalListState;

      newList = { ...initialInternalListState, ...rest };

      state.value = {
        ...state.value,
        [pageKey]: newList,
      };
    },
    setInternalPage: (state, action: PayloadAction<PagePayload>) => {
      const { pageKey = '', ...rest } = action.payload || {};
      const listState = state.value[pageKey] || initialInternalListState;
      let newList: InternalPaginatedListState<any> = initialInternalListState;

      const currentPage = getCurrentPageResults(listState.internalResults, rest.page);
      newList = { ...listState, results: currentPage, page: rest.page };

      state.value = {
        ...state.value,
        [pageKey]: newList,
      };
    },
    // setQueryParamsV2: (state, action: PayloadAction<UrlQueryParamsPayload>) => {
    //   const { pageKey = '', ...rest } = action.payload || {};
    //   const listState = state.value[pageKey] || initialListV2State;
    //   let newList: InternalPaginatedListState<any> = initialListV2State;

    //   newList = { ...listState, queryParams: rest.queryParams };

    //   state.value = {
    //     ...state.value,
    //     [pageKey]: newList,
    //   };
    // },
    // setSortingParamsV2: (
    //   state,
    //   action: PayloadAction<UrlQueryParamsPayload>
    // ) => {
    //   const { pageKey = '', ...rest } = action.payload || {};
    //   const listState = state.value[pageKey] || initialListV2State;
    //   let newList: InternalPaginatedListState<any> = initialListV2State;

    //   newList = { ...listState, sortingParams: rest.sortingParams };

    //   state.value = {
    //     ...state.value,
    //     [pageKey]: newList,
    //   };
    // },

    toggleInternalLoading: (state, action: PayloadAction<LoadingPayload>) => {
      const { pageKey = '', ...rest } = action.payload || {};
      const listState = state.value[pageKey] || initialInternalListState;
      let newList: InternalPaginatedListState<any> = initialInternalListState;

      newList = { ...listState, loading: rest.loading };

      state.value = {
        ...state.value,
        [pageKey]: newList,
      };
    },
    setPaginatedInternalList: (
      state,
      action: PayloadAction<SetPaginatedListPayload>
    ) => {
      const {
        pageKey = '',
        count,
        results,
        loaded,
        sortingParams,
      } = action.payload || {};
      const listState = state.value[pageKey] || initialInternalListState;
      let newList: InternalPaginatedListState<any> = initialInternalListState;
      if (!(results instanceof Array)) {
        console.trace(results);
      }
      const results12 = [...(results || [])];
      const currentPage = getCurrentPageResults(results12, 1);
      newList = {
        ...listState,
        count: count,
        page: 1,
        internalResults: [...results12],
        results: [...currentPage],
        loading: false,
        loaded,
        sortingParams,
      };

      state.value = {
        ...state.value,
        [pageKey]: newList,
      };
    },
  },
});

export const {
  reloadInternalList,
  setInternalPage,
  // setInternalQueryParams,
  // setInternalSortingParams,
  toggleInternalLoading,
  setPaginatedInternalList,
} = internalPaginatedListSlice.actions;

export default internalPaginatedListSlice.reducer;
