import { FC, useState } from "react";
import { Responsive } from "../../../layout";
import {
  ContainerFlex,
  Portal,
  Typography,
} from "../../../toolkit";
import {
  CurrentSubscriptionPlan,
  FunctionalityList,
  SubscriptionPlan,
} from "../../../components";
import InvoiceList from "../InvoiceList/InvoiceList";
import { useCreateSubscription, useCurrentSubscription } from "../../hooks";
import { API_URLS, parseDate, useInternalPaginatedList } from "../../../lib";
import PaymentMethodForm from "../PaymentMethodForm/PaymentMethodForm";
import CancelSubscription from "./CancelSubscription";
import RestoreSubscription from "./RestoreSubscription";
import { useAppSelector } from '../../../store';
import CheckoutSubscription from './CheckoutSubscription';
import ConfirmVAT from './ConfirmVAT';

// const ONE_MINUTE = 1000 * 60;
// const ONE_DAY = ONE_MINUTE * 60 * 24;
// const END_OF_DAY = ONE_DAY - ONE_MINUTE;
// const date = new Date();
// date.setHours(0);
// date.setMinutes(0);
// date.setSeconds(0);
// date.setMilliseconds(0);
// const DEFAULT_START = new Date(date.getTime() + END_OF_DAY);
// date.setDate(1);
// const DEFAULT_END = new Date(date);

const Subscriptions: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVATConfirmed, setVATConfirmed] = useState(false);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isRestoreOpen, setIsRestoreOpen] = useState(false);

  const {
    currentSubscription,
    paymentMethod,
    cancelSubscription,
    restoreSubscription,
  } = useCurrentSubscription();
  const {
    products,
    selectedPriceId,
    setSelectedPriceId,
    checkoutSubscription,
  } = useCreateSubscription();

  const company = useAppSelector(state => state.cache.company);
  const listProps = useInternalPaginatedList(API_URLS.platform.invoiceList);
  const hasInvoices = listProps.results.length > 0;

  return (
    <ContainerFlex type='column' spacing={2}>
      <Typography type="h5">Abonament</Typography>
      {currentSubscription ? (
        <CurrentSubscriptionPlan
          type={
            currentSubscription?.amount / 100 === 150
              ? "monthly"
              : currentSubscription?.amount / 100 === 810
                ? "semester"
                : "yearly"
          }
          currentSubscription={currentSubscription}
          paymentMethod={paymentMethod}
          onCancel={() => setIsCancelOpen(true)}
          onRestore={() => setIsRestoreOpen(true)}
          onChangeCard={() => setIsOpen(true)}
        />
      ) : (
        <Responsive colsMd={3}>
          <SubscriptionPlan
            type="monthly"
            onPlanClick={() => {
              setSelectedPriceId(products?.["1month"].priceId);
            }}
            isCurrent={false}
            hasSubscription={Boolean(currentSubscription)}
          />
          <SubscriptionPlan
            type="semester"
            onPlanClick={() => {
              setSelectedPriceId(products?.["6month"].priceId);
            }}
            isCurrent={false}
            hasSubscription={Boolean(currentSubscription)}
          />
          <SubscriptionPlan
            type="yearly"
            onPlanClick={() => {
              setSelectedPriceId(products?.["1year"].priceId);
            }}
            isCurrent={false}
            hasSubscription={Boolean(currentSubscription)}
          />
        </Responsive>
      )}
      <FunctionalityList expanded={!hasInvoices} />
      {hasInvoices && (
        <>
          <Typography type="h5">Facturile mele</Typography>
          <InvoiceList {...listProps} />
        </>
      )}
      <Portal
        isOpen={Boolean(selectedPriceId)}
        onDismiss={() => {
          setSelectedPriceId(undefined);
        }}
        title="Finalizare plata"
        overlay={true}
        width={560}
      >
        {!company.company?.vat && !isVATConfirmed ? (
          <ConfirmVAT
            companyName={company.company?.name!}
            onClose={() => {
              setSelectedPriceId(undefined);
            }}
            onContinue={() => {
              setVATConfirmed(true);
            }}
          />
        ) : (
          <CheckoutSubscription
            products={products!}
            selectedPriceId={selectedPriceId!}
            onCancel={() => {
              setSelectedPriceId(undefined);
            }}
            onCheckout={() => {
              checkoutSubscription(selectedPriceId!);
            }}
          />
        )}
      </Portal>
      <Portal
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
        }}
        title="Adauga noua metoda de plata"
        overlay={true}
        width={560}
      >
        <PaymentMethodForm
          paymentMethodId={paymentMethod?.paymentMethodId!}
          onClose={() => {
            setIsOpen(false);
          }}
          onSaved={() => {
            listProps.reload();
            setIsOpen(false);
          }}
        />
      </Portal>
      <Portal
        isOpen={isCancelOpen}
        onDismiss={() => {
          setIsCancelOpen(false);
        }}
        title="Revocare subscriptie"
        overlay={true}
        width={560}
      >
        <CancelSubscription
          currentPeriodEnd={parseDate(currentSubscription?.currentPeriodEnd!)}
          onClose={() => {
            setIsCancelOpen(false);
          }}
          onSave={() => {
            cancelSubscription(currentSubscription?.subscriptionId!);
            setIsCancelOpen(false);
          }}
        />
      </Portal>
      <Portal
        isOpen={isRestoreOpen}
        onDismiss={() => {
          setIsRestoreOpen(false);
        }}
        title="Restaurare subscriptie"
        overlay={true}
        width={560}
      >
        <RestoreSubscription
          currentPeriodEnd={parseDate(currentSubscription?.currentPeriodEnd!)}
          onClose={() => {
            setIsRestoreOpen(false);
          }}
          onSave={() => {
            restoreSubscription(currentSubscription?.subscriptionId!);
            setIsRestoreOpen(false);
          }}
        />
      </Portal>
    </ContainerFlex>
  );
};

export default Subscriptions;
