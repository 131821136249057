import { FC } from 'react';
import { Container } from '../../../layout';
import { AnimatedAppIcon, Button, Star3Icon, Star4Icon, StarIcon, Typography } from '../../../toolkit';
import { TitanPrice } from '../../../models';

type Props = {
  products: Record<string, TitanPrice>;
  selectedPriceId: string;
  onCancel: () => void;
  onCheckout: () => void;
}

const CheckoutSubscription: FC<Props> = ({products, selectedPriceId, onCancel, onCheckout }) => {
  return (
    <Container vertical gap={24}>
      <AnimatedAppIcon>
        {products?.["1month"].priceId === selectedPriceId && (
          <StarIcon size={40} />
        )}
        {products?.["6month"].priceId === selectedPriceId && (
          <Star3Icon size={40} />
        )}
        {products?.["1year"].priceId === selectedPriceId && (
          <Star4Icon size={40} />
        )}
      </AnimatedAppIcon>
      <Typography type="h5" variation="center">
        Ai ales abonament cu plata{" "}
        {products?.["1month"].priceId === selectedPriceId &&
          "in fiecare luna."}
        {products?.["6month"].priceId === selectedPriceId && "la 6 luni."}
        {products?.["1year"].priceId === selectedPriceId && "o data pe an."}
      </Typography>
      <Typography type="p" variation="center">
        Pentru a finaliza plata va vom redirectiona <br /> catre furnizorul
        folosit de noi pentru plata recurenta
      </Typography>
      <Container gap={10} horizontalAlign="end">
        <Button
          size="medium"
          variant="outlined"
          type="button"
          onClick={onCancel}
        >
          Renunta
        </Button>
        <Button
          size="medium"
          variant="contained"
          type="button"
          onClick={onCheckout}
        >
          Confirma
        </Button>
      </Container>
    </Container>
  );
};

export default CheckoutSubscription;