import { useCallback, useEffect } from 'react';
import { TableProps } from '../types';
import { TABLE_DEFAULTS } from '../TABLE_DEFAULTS';
import { useAppStoreHooks } from '../../toolkit';
import {
  setPageV2,
  setPaginatedListV2,
  setQueryParamsV2,
  setSortingParamsV2,
  useAppSelector,
} from '../../store';

type Params = Record<string, string | number | boolean>;

const defaultTableState = { results: [], page: 0 };

const usePaginatedListV2 = (
  url: string,
  params?: { queryParams?: Params; sortingParams?: Params }
): TableProps => {
  const tableState =
    useAppSelector((state) => state.paginatedListV2.value[url]) ||
    defaultTableState;

  const { getJson, notify, loadingStart, loadingEnd, dispatch } =
    useAppStoreHooks();
  const loadList = useCallback(
    async (page: number, queryParams?: Params, sortingParams?: Params) => {
      if(tableState.loaded) {
        // should I handle this?
      }
      loadingStart();
      let internalUrl = url + (url.includes('?') ? '&' : '?');
      internalUrl += `skip=${page * TABLE_DEFAULTS.pageSize}`;
      if (queryParams) {
        internalUrl = Object.keys(queryParams).reduce(
          (accumulator, current) => {
            if (queryParams![current] !== undefined) {
              accumulator += `&${current}=${queryParams![current]}`;
            }
            return accumulator;
          },
          internalUrl
        );
      }
      if (sortingParams) {
        internalUrl = Object.keys(sortingParams).reduce(
          (accumulator, current) => {
            if (sortingParams![current] !== undefined) {
              accumulator += `&${current}=${sortingParams![current]}`;
            }
            return accumulator;
          },
          internalUrl
        );
      }
      try {
        const response = await getJson<{ count: number; results: any[] }>(
          internalUrl
        );
        dispatch(
          setPaginatedListV2({
            pageKey: url,
            results: [...(response?.results || [])],
            count: response.count || 0,
            loaded: true,
            queryParams,
            sortingParams,
          })
        );
        loadingEnd();
      } catch (e: any) {
        notify(e.message || e, 'error');
        loadingEnd();
      }
    },
    [url, dispatch, getJson, notify, loadingStart, loadingEnd, tableState.loaded]
  );

  const changePage = useCallback(
    (page: number) => {
      dispatch(setPageV2({ pageKey: url, page }));
    },
    [dispatch, url]
  );

  const reload = useCallback(() => {
    if (tableState.page !== 0) {
      changePage(0);
    } else {
      loadList(
        tableState.page,
        tableState.queryParams || params?.queryParams,
        tableState.sortingParams || params?.sortingParams
      );
    }
  }, [
    changePage,
    loadList,
    tableState.page,
    tableState.queryParams,
    tableState.sortingParams,
    params?.queryParams,
    params?.sortingParams,
  ]);

  const setQueryParam = useCallback(
    (queryParams?: Params) => {
      dispatch(setQueryParamsV2({ pageKey: url, queryParams }));
    },
    [dispatch, url]
  );

  const setSortingParam = useCallback(
    (sortingParams?: { [key: string]: any }) => {
      dispatch(setSortingParamsV2({ pageKey: url, sortingParams }));
    },
    [dispatch, url]
  );

  const queryParams =
    tableState.queryParams === undefined
      ? params?.queryParams
      : tableState.queryParams;
  const sortingParams =
    tableState.sortingParams === undefined
      ? params?.sortingParams
      : tableState.sortingParams;

  const setSearchTerm = useCallback(
    (searchTerm?: string) => {
      if (!searchTerm) {
        searchTerm = undefined;
      }
      let _queryParams = { ...(queryParams || {}) };
      if (_queryParams.searchTerm !== searchTerm) {
        if (searchTerm) {
          _queryParams = {
            ...queryParams,
            searchTerm,
          };
        } else {
          delete _queryParams.searchTerm;
        }

        setQueryParam(_queryParams);
      }
    },
    [queryParams, setQueryParam]
  );

  useEffect(() => {
    loadList(tableState.page, queryParams, sortingParams);
  }, [loadList, tableState.page, queryParams, sortingParams]);

  return {
    loading: tableState.loading,
    isFiltered: Boolean(tableState.queryParams?.searchTerm),
    page: tableState.page,
    results: tableState.results,
    total: tableState.count || 0,
    sortingParams: tableState.sortingParams,
    setSearchTerm,
    setQueryParam,
    setSortingParam,
    reload,
    changePage,
  };
};

export default usePaginatedListV2;
