import { FC, HTMLAttributes, ReactNode } from 'react';
import styles from './styles.module.css';

type Props = {
  type: 'h1' | 'h2' | 'h3'| 'h4'| 'h5'| 'h6' | 'p' | 'span' | 'label';
  variation?: 'display1' | 'display2' | 'bold' | 'uppercase' | 'secondary' | 'center' | 'danger';
  variation1?: 'display1' | 'display2' | 'bold' | 'uppercase' | 'secondary' | 'center' | 'danger';
  truncate?: boolean;
  children?: ReactNode;
  htmlFor?: string;
} & HTMLAttributes<HTMLElement>;


const Typography: FC<Props> = ({ type, children, className, variation, variation1, truncate = false, htmlFor, ...rest }) => {
  const Component = type;
  return (
    <Component 
      className={[
        className, 
        styles.typography, 
        truncate ? styles.truncate : '',
        variation ? styles[variation] : '',
        variation1 ? styles[variation1] : ''].join(' ')} 
      {...rest}
      htmlFor={htmlFor}
    >
      {children}
    </Component>
  );
};

export default Typography;