import { FC } from 'react';
import { Button, ContainerFlex, Link, Typography } from '../../../toolkit';

type Props = {
  companyName: string;
  onClose: () => void;
  onContinue: () => void;
};

const ConfirmVAT: FC<Props> = ({ companyName, onClose, onContinue }) => {
  return (
    <ContainerFlex type="column" spacing={2}>
      <Typography type="h5" variation="bold">
        Ai ales sa creezi o subsciptie noua.
      </Typography>
      <Typography type="p">
        In cazul in care doriti ca factura sa fie emisa pe persona juridica <b>{companyName}</b>,
        trebuie sa adaugati CUI in formularul companie din{' '}
        <Link to="/settings/company" variation="primary">
          Setari - Companie.
        </Link>
      </Typography>
      <Typography type="p">
        Pentru a emite factura pe persoana fizica, continua la pasul urmator.
      </Typography>
      <ContainerFlex type="row" spacing={2} justify="end">
        <Button
          type="button"
          size="medium"
          variant="outlined"
          onClick={onClose}
        >
          Adaug CUI
        </Button>
        <Button size="medium" variant="contained" type="button" onClick={onContinue}>
          Continua fara CUI
        </Button>
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default ConfirmVAT;
