import { useMemo, useState } from 'react';
import { TitanElement, TitanElementStage } from '../../models';
import { API_URLS, validations } from '../../lib';
import { useAppStoreHooks } from '../../toolkit';
import { textMessages } from '../../translations';
import { useAppSelector } from '../../store';

const validate = validations({
  name: [['required', 'Camp oligatoriu!']],
  price: [
    ['required', 'Camp oligatoriu!'],
    ['number', 'Pretul trebuie sa fie un numar!'],
  ],
});

const useElement = (
  onSaved: (elementStages?: TitanElementStage[]) => void,
  element?: TitanElement
) => {
  const [elementForm, setElementForm] = useState<{
    form: TitanElement;
    errors: {
      name?: string;
      description?: string;
      price?: string;
    };
  }>({
    form: element || ({} as TitanElement),
    errors: {},
  });
  const stages = useAppSelector((state) => state.cache.stages.data);
  const stagesOptions = useMemo(
    () => stages?.map((e) => ({ key: e.stageId!, name: e.name })),
    [stages]
  );

  const { putJson, postJson, notify, loadingStart, loadingEnd } =
    useAppStoreHooks();

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setElementForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [target.name]: target.value,
      },
    }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let element = { ...elementForm.form };
    const errors = validate(element);
    const hasError = Object.keys(errors).length;
    setElementForm((prevState) => ({
      ...prevState,
      errors,
    }));
    if (hasError) {
      return;
    }
    loadingStart();
    try {
      const elementStages = [...(element.elementStages || [])];
      const stageIds = elementStages.map((es) => es.stageId);
      delete element.elementStages;
      if (element.elementId) {
        await putJson<TitanElement>(API_URLS.titan.elements.update, {
          ...element,
          price: +element.price,
          stageIds,
        });
        onSaved(
          elementStages.map((es) => ({
            ...es,
            elementId: element.elementId,
            elementName: element.name,
          }))
        );
      } else {
        element = await postJson<TitanElement>(API_URLS.titan.elements.create, {
          ...element,
          price: +element.price,
          stageIds,
        });
        onSaved();
      }
      loadingEnd();
      notify('Elementul a fost salvat cu success', 'success');
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error', e.message);
    }
  };

  const onStageChanged = (stageId: number) => {
    const stage = stages?.find((s) => s.stageId === stageId);
    setElementForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        elementStages: [
          ...(prevState.form.elementStages || []),
          {
            stageId: stage!.stageId,
            stageName: stage!.name,
          } as TitanElementStage,
        ],
      },
    }));
  };

  const onRemoveElementStage = (index: number) => {
    setElementForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        elementStages: (prevState.form.elementStages || []).filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  return {
    elementForm,
    stagesOptions: elementForm.form.elementStages
      ? stagesOptions?.filter(
          (so) =>
            elementForm.form.elementStages?.some(
              (es) => es.stageId === so.key
            ) === false
        )
      : stagesOptions,
    onChange,
    onSubmit,
    onStageChanged,
    onRemoveElementStage,
  };
};

export default useElement;
